<template lang="pug">
  .sale-stop-settings.filters
    .filters-bulk-actions
      AppButton.action-button.btn-other.w-25(
        :disabled="disabled"
        title="sale_stop_settings.all_on"
        @click="bulkActiveOn"
      )
      AppButton.action-button.btn-other.w-25(
        :disabled="disabled"
        title="sale_stop_settings.all_off"
        @click="bulkActiveOff"
      )
      AppButton.btn-add-new.w-25(
        :disabled="disabled"
        title="sale_stop_settings.bulk"
        @click="openBulkModal"
      )
    .filters-search
      AppSearch(
        :disabled="disabled"
        :placeholder="$t('sale_stop_settings.search_car_class')"
        @update="applySearch"
      )
      AppButton.btn-confirm.short(
        :disabled="isEmpty(itemsUpdated)"
        title="actions.save_changes"
        @click="saveChanges"
      )
</template>

<script>
  import { mapGetters } from "vuex"
  import { isEmpty } from "lodash-es"

  export default {
    props: {
      disabled: {
        type: Boolean,
        default: false
      }
    },

    components: {
      AppButton: () => import("@/components/elements/AppButton"),
      AppSearch: () => import("@/components/elements/AppSearch")
    },

    computed: {
      ...mapGetters("saleStopSettings", ["itemsUpdated"])
    },

    methods: {
      isEmpty,

      bulkActiveOn() {
        this.$emit("bulk-change-active", true)
      },

      bulkActiveOff() {
        this.$emit("bulk-change-active", false)
      },

      applySearch(searchValue) {
        this.$emit("handle-search", searchValue)
      },

      saveChanges() {
        this.$emit("save-changes")
      },

      openBulkModal() {
        this.$emit("open-bulk-modal")
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"

  .sale-stop-settings
    &.filters
      display: flex
      justify-content: space-between
      margin-bottom: 20px

      .filters-bulk-actions
        .app-button
          margin-right: 10px
          min-width: 85px

      .filters-search
        display: flex
        justify-content: right

        ::v-deep
          .app-search
            margin-right: 10px
            width: 370px
</style>
